.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

th {
  text-align: left;
}

.col-width {
  width: 200px;
}

table {
  border-collapse: initial !important;
  width: auto !important;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit !important;
  border-width: 0 !important;
}

.navbar {
  background-color: black;
  padding: 5px;
}

.navbar-light .navbar-brand{
  color: #f8f9fa !important;
}
.navbar-light .navbar-nav .nav-link {
  color: #f8f9fa !important;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
  left: auto;
  right: 0;
}

.dropdown-toggle::after {
  display: none !important; 
}

.user-info {
  display: flex; 
  align-items: center;
  font-weight: 500;
  padding-left : 10px
}

.navbar-expand .navbar-nav .dropdown-menu .show{
  margin-top: 10px !important;
}

.overlay {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color:rgba(0, 0, 0, 0.4);
  background: url(data:;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAABl0RVh0U29mdHdhcmUAUGFpbnQuTkVUIHYzLjUuNUmK/OAAAAATSURBVBhXY2RgYNgHxGAAYuwDAA78AjwwRoQYAAAAAElFTkSuQmCC) repeat scroll transparent\9; /* ie fallback png background image */
  z-index:9999;
  color:white;
  overflow-x: hidden;
}

.loader {
  width: 100%;
  margin-left: calc(50% - 50px);
  margin-top: 250px;
}

.container {
  border-radius: 5px;
  display: inherit !important;
}

.items {
  overflow: hidden;
}
.card {
  background-color: white !important;
  margin: 10px;
  border:  1px solid rgba(100, 100, 100, 0.4);
  box-shadow: 0 3px 8px rgb(0 0 0 / 25%);;
}

.pr10 {
  padding-right: 10px;
}

.c-white {
  color: white;
  text-decoration: none;
}

.react-tabs__tab-list {
  text-align: left;
}

.sweet-alert h2 {
  text-align: left !important;
}

.btn-danger {
  margin-left: auto ;
}